import { React, PropTypes, Helmet, Link } from '@/vendor'
import { Mq, Grid, MastheadSubpage, Masthead, Fs, Button } from '@/components'

class Cell extends React.PureComponent {
	static propTypes = {
		title: PropTypes.string,
		body: PropTypes.node,
		footer: PropTypes.node,
	}
	render() {
		return (
			<Grid.Cell>
				<Grid.Content pad={2}>
					<Fs align="center" contained>
						<Fs variant="h2" tag="h2">
							{this.props.title}
						</Fs>
						{this.props.body}
					</Fs>
					{this.props.footer && (
						<>
							<br />
							{this.props.footer}
						</>
					)}
				</Grid.Content>
			</Grid.Cell>
		)
	}
}

class ContactRoute extends React.PureComponent {
	render() {
		return (
			<>
				<Helmet>
					<title>Contact</title>
				</Helmet>
				<MastheadSubpage
					masthead={
						<Masthead
							background={{
								image: require('./contact.png'),
								title: 'Contact',
							}}
							foreground={<Masthead.Foreground.Default headline="Get In Touch" />}
						/>
					}
					body={
						<Mq>
							{(Mq) => (
								<>
									<Grid stacked={!Mq.large}>
										<Cell
											title="Distribution"
											body={
												<p>
													<a href="mailto:nicolas@williamscorner.com">
														Williams Corner (VA, DC, MD)
													</a>
													,<a href="mailto:jahde@zrswines.com">ZRS Wines (NY)</a>
													<br />
													<a href="mailto:roni@roniselects.com">Roni Selects (CA)</a>,
													<a href="mailto:nm@avantpartir.com">Avant Partir (SC, GA)</a>
													<br />
													<a href="mailto:info@americanwinecollective.com">
														American Wine Collective (VT, NH)
													</a>
													<br />
													<a href="mailto:kate@riseoverrunwine.com">Rise Over Run (NC)</a>
												</p>
											}
										/>
										<Cell
											title="Support"
											body={
												<p>
													<a href="mailto:wines@lightwellsurvey.com">
														General Questions + Comments
													</a>
													<br />
													<a href="mailto:wines@lightwellsurvey.com">
														Tasting Room Inquiries
													</a>
													<br />
													<Link to="/legal">Shipping + Returns</Link>
													<br />
													<Link to="/legal">Wine Club Policy</Link>
												</p>
											}
										/>
									</Grid>
									<Grid stacked={!Mq.large}>
										<Cell
											title="Tasting Room"
											body={
												<>
													<p>
														We are currently hibernating and will be closed for the remainder of the 
														winter season. Sign up for our emails to get the latest deets about where 
														we’ll be popping up and when our tasting room will re-open. For questions, 
														please email{' '}
														<span
															style={{
																whiteSpace: 'nowrap',
															}}
														>
															at{' '}
															<a href="mailto:wines@lightwellsurvey.com">
																wines@lightwellsurvey.com
															</a>
															.
														</span>
													</p>
													<p>
														200 W. 12TH ST, UNIT# D53
														<br />
														Waynesboro, VA. 22980
													</p>
												</>
											}
											footer={
												<p>
													<Button
														tag="a"
														href=""
														target="_blank"
													>
														Book a Tasting
													</Button>
												</p>
											}
										/>
										<Grid.Cell>
											<Grid.Content
												tag="a"
												href="https://maps.app.goo.gl/wB6J4BfjR52ymMhL6"
												target="_blank"
												bg={require('./contact-map-tall.jpg')}
											/>
										</Grid.Cell>
									</Grid>
								</>
							)}
						</Mq>
					}
				/>
			</>
		)
	}
}

export default ContactRoute
